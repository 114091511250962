@font-face {  font-family: 'PalatinoLTStd-Black';         src: url('../assets/fonts/PalatinoLTStd-Black.otf') format('opentype'); }
@font-face {  font-family: 'PalatinoLTStd-BlackItalic';   src: url('../assets/fonts/PalatinoLTStd-BlackItalic.otf') format('opentype'); }
@font-face {  font-family: 'PalatinoLTStd-Bold';          src: url('../assets/fonts/PalatinoLTStd-Bold.otf') format('opentype'); }
@font-face {  font-family: 'PalatinoLTStd-BoldItalic';    src: url('../assets/fonts/PalatinoLTStd-BoldItalic.otf') format('opentype');}
@font-face {  font-family: 'PalatinoLTStd-Italic';        src: url('../assets/fonts/PalatinoLTStd-Italic.otf') format('opentype'); }
@font-face {  font-family: 'PalatinoLTStd-Light';         src: url('../assets/fonts/PalatinoLTStd-Light.otf') format('opentype'); }
@font-face {  font-family: 'PalatinoLTStd-LightItalic';   src: url('../assets/fonts/PalatinoLTStd-Italic.otf') format('opentype'); }
@font-face {  font-family: 'PalatinoLTStd-Medium';        src: url('../assets/fonts/PalatinoLTStd-Medium.otf') format('opentype');}
@font-face {  font-family: 'PalatinoLTStd-MediumItalic';  src: url('../assets/fonts/PalatinoLTStd-MediumItalic.otf') format('opentype'); }
@font-face {  font-family: 'PalatinoLTStd-Roman';         src: url('../assets/fonts/PalatinoLTStd-Roman.otf') format('opentype');}

@font-face {  font-family: 'OpenSans-Bold';               src: url('../assets/fonts/OpenSans-Bold.ttf') format('truetype');  }
@font-face {  font-family: 'OpenSans-BoldItalic';         src: url('../assets/fonts/OpenSans-BoldItalic.ttf') format('truetype');  }
@font-face {  font-family: 'OpenSans-ExtraBold';          src: url('../assets/fonts/OpenSans-ExtraBold.ttf') format('truetype');  }
@font-face {  font-family: 'OpenSans-ExtraBoldItalic';    src: url('../assets/fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype');  }
@font-face {  font-family: 'OpenSans-Italic';             src: url('../assets/fonts/OpenSans-Italic.ttf') format('truetype');  }
@font-face {  font-family: 'OpenSans-Light';              src: url('../assets/fonts/OpenSans-Light.ttf') format('truetype');  }
@font-face {  font-family: 'OpenSans-LightItalic';        src: url('../assets/fonts/OpenSans-LightItalic.ttf') format('truetype');  }
@font-face {  font-family: 'OpenSans-Regular';            src: url('../assets/fonts/OpenSans-Regular.ttf') format('truetype');  }
@font-face {  font-family: 'OpenSans-SemiBold';           src: url('../assets/fonts/OpenSans-SemiBold.ttf') format('truetype');  }
@font-face {  font-family: 'OpenSans-SemiBoldItalic';     src: url('../assets/fonts/OpenSans-SemiBoldItalic.ttf') format('truetype');  }
