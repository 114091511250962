@import '../../styles/_variables.scss';

.content-box {
	background-color: $color-table-header;
    margin: 0 0 12px 0;
    padding: 12px 12px 12px 12px;
}

.status-button {
	margin-top: 2.4em;

	button {
		padding-top: 0.4em;
		padding-bottom: 0.4em;
		height: 100%;
	}
}
