/* Page template */
$page-min-width: 680px;

$color-gray-lighter: #efefef;
$color-menu-background: #d9e4e4;
$color-triplea-grey: #575757;

//$font-header-family: 'PalatinoLTStd-Medium', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;

$font-header-family: 'OpenSans-SemiBold', 'open sans', arial, helvetica, sans-serif;
