/* Global */

$color-border-form: #8a8a8a; // !
$color-gray-light: #bcbcbc;
$color-gray-lighter: #efefef;
$color-links-and-hover: #00615c;
$color-menu-background: #d9e4e4;
$color-secondary-color: #009b96;
$color-triplea-grey: #575757;

$focus-outline-color: #009b96;
$focus-outline-offset: 2px;
$focus-outline-width: 2px;

$font-bread-regular-family: 'OpenSans-Regular', 'open sans', arial, helvetica, sans-serif;
$font-bread-semibold-family: 'OpenSans-SemiBold', 'open sans', arial, helvetica, sans-serif;
// $font-header-family: 'PalatinoLTStd-Medium', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
// $font-header-italic-family: 'PalatinoLTStd-Italic', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;

$color-table-header: #eaf4f4;

/* 
 Tilsyn variables as 2020.12.08 
*/
// $color-fontfarge: #222222;
// $color-helt-hvit: #FFFFFF;
// $color-bakgrunn: #EBF3F3;
// $color-morkere-bakgrunn: #D2E5E5;
// $color-hvite-bakgrunner: #FEFEFE;
// $color-lysegraa-bakgrunner: #FCF9F9;
// $color-meny: #d9e4e4;

// $color-hovedfarge: #004B46;
// $color-lenker-og-hover: #00615C;
// $color-sekundarfarge: #009B96;
// $color-sekundarfarge_aktivlenke: #008480;
// $color-green-dark-button: #0f6a65;
// $color-border-focus: #56bab7;
// $color-utskrift: #059b96;

// $color-skillelinje: #CCCCCC;
// $color-kantlinje-skjema: #8A8A8A;

// $color-kommentar: #FCE4CA;
// $color-feilmeldinger: #CA0000;
// $color-error-border: #E91E63;
// $color-advarsel: #FAF8DD;

// $color-dawn-pink: #FAF4F4;
// $color-triplea-grey: #575757;
// $color-label-grey: #3c3f3f;
// $color-header-grey: #9bd8d6;
// $color-box-grey: #f5f5f5;
// $color-pumpkin: #EF7D00;
// $color-ocher: #86660E;

// $color-yellow-light: #F9CB99;
// $color-red-light: #EA9999;
// $color-red: #CA0000;
// $color-red-dark: #a30000;
// $color-blue-light: #99C0BE;
// $color-gray-light: #BCBCBC;
// $color-gray-lighter: #EFEFEF;
// $color-blue-green: #94D4D2;
