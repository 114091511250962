@import '../../styles/template';
@import '../../styles/navbar';

.main-container {
	min-width: $page-min-width;
	padding: 0 !important;
}

.sidenav-col {
	padding: 0 !important;
	max-width: $navbar-width;
}
