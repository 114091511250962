@import '../../styles/template';
@import '../../styles/navbar';

.sidebar {
	padding-top: 16px;
	font-family: $font-header-family;

	.list-item-active {
		background-color: $color-gray-lighter;
	}

	.menu-list-item {
		cursor: pointer;
		outline-width: 0;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 16px;
		white-space: nowrap;

		&:hover {
			background-color: $color-gray-lighter;
			transition: 0.4s;
		}

		&:active {
			background-color: $color-gray-lighter;
			transition: 0.4s;
		}

		a {
			text-decoration: none;

			svg {
				vertical-align: middle;

				width: $navbar-icon-width;
			}

			.menu-item-text {
				text-decoration: none;
				color: $color-triplea-grey;
				font-size: 16px;
				line-height: 26px;
				font-weight: 300;
				margin-left: 16px;
			}
			
		}
	}

	.bottom-divider {
		border-bottom: 2px solid #558784;
		margin-left: 16px;
		margin-right: 16px;
		width: 90%;
		color: #548683;
	}
}
