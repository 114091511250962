@import "../../styles/variables";

.info-field-group--title {
	// border-bottom: 1px solid $color-border-form;
	display: inline-block;
	margin-bottom: 4px;
  text-decoration: underline;
}

.info-field-row {
  margin-bottom: 4px;
}

.info-field-group {
	background-color: $color-table-header;
  margin: 8px;
  padding: 12px 12px 12px 12px;
}

.idporten-container {
  margin: 0px 20px;
  font-style: italic;
}
