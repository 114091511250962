.responsible-table > td > div {
  margin: 4px 1.2em 4px 0;
  text-align: center;
}

.responsible-table > td > div > input {
  left: 50% !important;
  right: 50%;
}

.responsible-table > td > div > label:last-child {
  width: 0;
}

.responsible-table-header > tr > td {
  text-align: center;
}