@mixin multiselectContainer() {
	border: 1px solid #000;
	box-shadow: 3px 3px 5px #dfdfdf;
	-webkit-box-shadow: 3px 3px 5px #dfdfdf;
	-moz-box-shadow: 3px 3px 5px #dfdfdf;
	padding: 10px;
	margin-bottom: 10px;

	.close-multiselect {
		cursor: pointer;
		&:hover {
			opacity: 0.5;
		}
	}
}
