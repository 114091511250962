@import '~bootstrap/scss/bootstrap';
@import '~react-notifications-component/dist/theme.css';
@import './styles/fonts';
@import './styles/variables';

body {
	margin: 0;
}

::-webkit-scrollbar {
	width: 5px;
}
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb {
	background: $color-secondary-color;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
	background: $color-secondary-color;
}

#root {
	position: relative;
}

// similar to bootstrap text-break (break-word)
.text-break-all {
	word-break: break-all;
}
